@import 'theme';

.container{
    display: flex;
    flex-direction: column;
    margin: 0 2rem;

    p{
        width: 100%;
        font-family: MonSemiBold;
        font-size: $mon17;
        text-align: center;
    }

    .labels{
        display: flex;
        flex-direction: row;
        
        p{
            color:$doveGray;
            font-size: $mon13;
            font-family: MonMedium;
            margin: 0;
        }
    }
}