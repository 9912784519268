@import "theme";

.container{
    height: $pageHeight;
    width: 100vw;
    display: flex;
    overflow: scroll;
    flex-direction: column;
    padding-top: 4rem;
    
    .linkWrapper{
        text-align: left;
        margin: 1rem auto 1rem 2rem
    }
}