@import "theme";

.wrapper{
    // height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;

    .form{
        display: flex;
        flex-direction: column;
        width: 100vw;
        margin: 0.5rem auto 0.5rem auto;

        .buttonContainer{
            // margin: auto 0 1rem 0;
            
            .submitBowiButton{
                display: block;
                margin: auto auto 0 auto;
            }
        }
    }

    .logo{
        display: block;
        margin: 2rem auto 0.5rem auto;
        height: 12rem;
    }

    .text{
        display: block;
        grid-area: text;
        font-family: MonSemiBold;
        font-size: $mon17;
        margin: 0.5rem auto 1rem auto;
    }

    .question{
        display: flex;
        flex-direction: row;
        color: $doveGray;
        margin: auto auto 4rem auto;

        p{
            margin: 0 1rem auto auto
        }

        .signUp{
            display: block;
            margin: auto;
        }

    }
}