@import 'theme';

.container{
    width: 100vw;
    height: 4rem;
    display: flex;
    flex-direction: column;
    font-family: MonMedium;
    font-size: $mon17;
    text-align: center;

    div{
        margin: 0.5rem auto;
    }

    .error{
        margin: 0 auto;
        color: $cabaret;
        font-size: $mon13;
        font-family: MonSemiBold;
    }

    .ok{
        margin: 0 auto;
        color: $jewel;
        font-size: $mon13;
        font-family: MonSemiBold;
    }

    .loading{
        margin: auto;
    }
}