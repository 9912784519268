@import 'theme';

.wrapper {
  .error {
    background: $errorFill;
    border: 1px solid $errorBorder;
    border-radius: 15px;
    margin: auto 2rem 2rem 2rem;
    display: grid;
    grid-template-areas: 
    "message close";

  }

  .info {
    background: $white;
    border: 1px solid $doveGray;
    border-radius: 15px;
    margin: auto 2rem 2rem 2rem;
    display: grid;
    grid-template-areas: 
    "message close";
  }

  .success {
    background: $successFill;
    border: 1px solid $successBorder;
    border-radius: 15px;
    margin: auto 2rem 2rem 2rem;
    display: grid;
    grid-template-areas: 
    "message close";
  }
}

.message {
  color: $doveGray;
  font-size: $mon17;
  font-family: MonSemiBold;
  grid-area: message;
  display: flex;
}

.infoIcon {
  margin-right: auto 2rem auto 1rem;
  color: $doveGray;
  grid-area: icon;
  margin-right: 1rem;
  opacity: 0.9;
}

.errorIcon {
  margin-right: auto 2rem auto 1rem;
  color: $doveGray;
  grid-area: icon;
  margin-right: 1rem;
  opacity: 0.9;
}

.successIcon {
  margin-right: auto 2rem auto 1rem;
  color: $successBorder;
  grid-area: icon;
  margin-right: 1rem;
  opacity: 0.9;
}

.close{
  color: $doveGray;
  grid-area: close;
  margin: auto 0 auto auto;
}
