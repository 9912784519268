@import 'theme';

.loginContainer{
    // background: url(../../assets/background/splash.png);
    background: $cabaret;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    height: 100vh;
    justify-content: space-between;

    .logo{
        margin-top: 4rem;
        height: 16rem;
    }

    .tap{
        font-family: MonMedium;
        color: $white;
        margin-bottom: 2rem;
        text-align: center;
        transform: scale(1);
        animation: pulse 2s infinite;
    }
}

.J{
    color: white;
    font-family: MonMedium;
    font-size: 2rem;
    margin: 0 auto;

    .charge{
        font-size: MonBold;
    }
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
	}

	50% {
		transform: scale(1);
	}

    100% {
		transform: scale(0.95);
	}
}
