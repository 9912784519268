@import 'theme';

.container{
    height: 100%;
    display: flex;
    flex-direction: column;

    .icon{
        margin: 1.5rem 2rem 1.5rem 2rem;
        height: 6rem;
    }

    .title{
        font-family: MonMedium;
        font-size: $mon17;
        display: block;
        margin: 0 auto;
    }

    a{
        text-decoration: none;
        margin: auto 0 1rem 0;
    }

    .buttonContainer{
        margin: auto 0 0 0;
    }

    .details{
        margin: 2rem 0 auto 0;
        display: grid;
        grid-template-areas: "col1 col2";

        .detailContent{
            display: block;
            width: 6rem;
            margin: auto auto 1rem auto;

            .title{
                color: $doveGray;
                font-size: 0.75rem;
                font-family: MonMedium;
            }

            p{
                font-family: MonSemiBold;
            }

        }
    }
}