@import 'theme';

.primary{
    margin: 1rem 2rem;
    font-size: $mon17;
    font-family: MonSemiBold;
    display: grid;
    height: 4.5rem;
    background-image: $lineGradientButton;
    border-radius: 20px;

    &:hover{
        cursor: pointer;
    }

    p{
        display: block;
        margin: auto;
        color: $white;
    }
}

.secondary{
    margin: auto 2rem;
    font-size: $mon17;
    font-family: MonSemiBold;
    border: double 2px transparent;
    border-radius: 20px;
    background-image: linear-gradient(white, white), $lineGradientButton;
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    display: grid;
    height: 4.5rem;

    &:hover{
        cursor: pointer;
    }

    p{
        display: block;
        margin: auto;
        color: $cabaret;
    }
}

.ternary{
    margin: 1rem 2rem;
    font-size: $mon17;
    font-family: MonSemiBold;

    &:hover{
        cursor: pointer;
    }

    p{
        display: block;
        color: $cabaret;
        text-align: left;
    }
}

.disabledPrimary{
    margin: 1rem 2rem;
    font-size: $mon17;
    font-family: MonSemiBold;
    display: grid;
    height: 4.5rem;
    background-image: $lineGradientButton ;
    opacity: 0.7;
    border-radius: 20px;

    &:hover{
        cursor: not-allowed;
    }

    p{
        display: block;
        margin: auto;
        color: $white;
    }
}

.disabledSecondary{
    margin: auto 2rem;
    font-size: $mon17;
    font-family: MonSemiBold;
    border: double 2px transparent;
    border-radius: 20px;
    background-image: linear-gradient(white, white), $lineGradientButton;
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    display: grid;
    height: 4.5rem;
    opacity: 0.7;

    &:hover{
        cursor: not-allowed;
    }

    p{
        display: block;
        margin: auto;
        color: $cabaret;
    }
}