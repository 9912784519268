@import 'theme';

.wrapper{
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    display: grid;
    grid-template-rows: auto;
    grid-gap: 1rem;
    font-size: 1.5rem;
    word-wrap: break-word;
    text-decoration: none;
}

