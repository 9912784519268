@import 'theme';

.spinnerWrapper{
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    background-color: rgba(255,255,255,0.75);        

    .spinner{
        width: 80px;
        margin: auto;
    }
}