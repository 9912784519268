@import 'theme';

.wrapper {
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow: scroll;

    .list{
        margin: 4rem 1rem 0 1rem;

        .listItem{

            .listAvatar {

                .avatar {
                    background:$lineGradientButton;
                }

                .info {
                    text-transform: capitalize;
                }
            }
        }
    }
}
