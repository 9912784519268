@import 'theme';

.wrapper{
    width: 100vw;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    padding-top: 4rem;
    word-wrap: break-word;
    text-decoration: none;

    .title{
        font-family: MonSemiBold;
        font-size: $mon17;
        color: $doveGray;
        margin: 2rem auto;
        text-align: center;
    }

    .icon{
        margin: 2rem auto;
        height: 7.5rem;
        
        .image{
            height: 7.5rem;
        }
    }

    .button{
        margin: 2rem auto;
    }

    .descriptionWrapper{
        
        margin: 1rem 2rem;

        .description{
            font-family: MonSemiBold;
            font-size: 0.875rem;
            color: $doveGray;
            text-align: left;
        }
    }

    .input{
        margin: 2rem auto
    }

    .spinnerWrapper{
        position: fixed;
        height: 100%;
        width: 100%;
        display: flex;
        background-color: rgba(255,255,255,0.75);        

        .spinner{
            width: 80px;
            margin: auto;
        }
    }
}
