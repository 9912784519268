@import 'theme';

.banner{
    position: fixed;
    height: 4rem;
    width: 100vw;
    background: $lineGradientButton;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    font-size: $mon17;
    color: $white;
    display: flex;

    div{
        display: block;
        margin: auto auto 1rem auto;
        span{
            font-family: MonBold;
        }   
    }
}