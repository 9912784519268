@import 'theme';

.footerContainer{
    position: fixed;
    width: 100vw;
    height: 4rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background: $whiteLilac;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border: solid 1px $white;
    border-bottom: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.53125rem 4rem;
    bottom: 0;
    left: 0;

    .icon{
        text-decoration: none;
        color: $tundora;
        width: 4rem;
        
        svg{
            display: block;
            margin: 0 auto;
        }

        p{
            font-size: $mon13;
            display: block;
            margin: 0;
            text-align: center;
        }
    }
}