@import "theme";

.container{
    position: absolute;
    overflow: hidden;
    margin: 4rem auto 4rem auto ;
    width: 100vw;
    height: $pageHeight;


    .scanner{
        width: 70vw;
        margin: 1rem auto 0rem auto;

        div{
            border-radius: 25px;
        }

        video{
            border-radius: 25px;
        }

    }

    .infoBanner{

        .image{
            display: block;
            width: 10rem;
            margin: auto;
        }

        .info{
            margin: 0;
            padding: 1rem;
            display: block;
            text-align: center;
        }
    }

}