@import "theme";

.formGroup {
  position: relative;
  padding: 1rem 0 0;
  margin: 1rem 2rem;
}

.formField {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid $doveGray;
  outline: 0;
  font-size: 1.3rem;
  color: $tundora;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .formLabel {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
  }
}

.formLabel {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $doveGray;
}

.formField:focus {
  ~ .formLabel {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: $cabaret;
  }
  padding-bottom: 6px;  
  border-width: 1px;
  border-image: $lineGradientButton;
  border-image-slice: 1;
}
/* reset input */
.formField{
  &:required,&:invalid { box-shadow:none; }
}