// Colors
$cabaret: #002642;
$tundora: #444444;
$doveGray: #6C6C6C;
$alto: #D8D8D8;
$white: #FFFFFF;
$violetRed: #F5317F;
$salmon: #FF7C6E;
$whiteLilac: #F8F7FD;
$jewel: #157751;
$aqua: #21d2fe;
$electricViolet: #A135FD;
$errorBorder: #C70011;
$errorFill: #F8E0E2;
$successBorder: #6BB400;
$successFill: #DEEDCF;
$infoBorder: #C8C8C8;

//Gradient
// $lineGradientButton: linear-gradient(135deg, $violetRed, $salmon);
$lineGradientButton: linear-gradient(135deg, #002642, #00477a);
$infoMessageGradient: linear-gradient(135deg, $aqua, $electricViolet);
$footerGradient: linear-gradient(138deg, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0.08) 0%, rgba(255,255,255,0.74) 100%);

//Fonts
@font-face {
    font-family: 'MonSemiBold';
    src: url(../fonts/Montserrat-SemiBold.ttf);
}

@font-face {
    font-family: 'MonMedium';
    src: url(../fonts/Montserrat-Medium.ttf);
}

@font-face {
    font-family: 'MonBold';
    src: url(../fonts/Montserrat-ExtraBold.ttf);
}

//Font-size
$mon17: 1.0625rem;
$mon13: 0.8125rem;

//calcs
$pageHeight: calc(100vh - 8rem);