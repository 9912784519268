@import 'theme';

.container{
    width: 100vw;
    // height: $pageHeight;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding-top: 4rem;

    .buttonContainer{
        margin: 2rem 0 1rem 0;
    }

    .notification{
        margin: auto 2rem 1rem 2rem;
    }
}
