@import 'theme';

.container{
    display: grid;
    margin: 2rem;
    grid-template-areas: 
    "energy connected finish";

    .energy{
        grid-area: energy;
    }

    .connected{
        grid-area: connected;
    }

    .finish{
        grid-area: finish;
    }

    .title{
        color: $doveGray;
        font-size: $mon13;
        margin: 0;
    }
}
