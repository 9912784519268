@import 'theme';

.container{
    position: absolute;
    bottom: 0;
    display: grid;
    grid-template-areas: 
    "description close"
    "button avatar";
    width: 100vw;
    height: 34vh;
    background: $white;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    animation: rise 1s;
    animation-direction: alternate;
    box-shadow: 0 -2px 2px 0 rgba(55,55, 55, .2);

    .installDescription{
        grid-area : description;
        font-family: MonMedium;
        font-size: $mon13;
        display: block;
        margin: auto 2rem;
    }
    
    .avatar{
        grid-area: avatar;
        margin: 0 2rem;
        height: 6rem;
        width: 6rem;
        border-radius: 10px;
    }

    .button{
        margin: auto 0 0 auto;
        width: 6rem;
        grid-area: button;
    }

    .close{
        grid-area: close;
        width: 1.5rem;
        margin: 1rem 2rem auto auto
    }
}


/* Animation code */
@keyframes rise {
    0% {  
        height: 0px; 
    }
    100% {
      height: 34vh;
    }
}