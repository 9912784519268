@import 'theme';

html,
body {
  font-family: MonMedium;
  min-height: 100%;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  width: 100vw;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  display:block;
  background: $footerGradient;
}

