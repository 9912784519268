@import "theme";

.container{

    height: 18rem;
    display: flex;
    flex-direction: column;

    .processing{
      font-family: MonSemiBold;
      font-size: $mon17;
      text-align: center;
      color: $doveGray;
    }

    .gooey{
    height: 4rem;
    width:  10rem;
    margin:  0 auto auto auto;
    background: white;
    filter: contrast(20);
    
        .dot{
            position: absolute;
            width: 1rem;
            height: 1rem;
            top: 12px;
            left: 15px;
            filter: blur(4px);
            background: rgb(51,51,51);
            border-radius: 50%;
            transform: translateX(0);
            animation: dot 2.8s infinite;
        }
        .dots{
            transform: translateX(0);
            margin-top: 12px;
            margin-left: 31px;
            animation: dots 2.8s infinite;

            span{
            display: block;
            float: left;
            width: 1rem;
            height: 1rem;
            margin-left: 1rem;
            filter: blur(4px);
            background: rgb(51,51,51);
            border-radius: 50%;
            }
        }
    }
}

@keyframes dot {
  50%{
    transform: translateX(96px)
  }
}
@keyframes dots{
  50%{
    transform: translateX(-31px)
    }
}