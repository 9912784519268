@import 'theme';

.container{
    width: 100vw;
    // height: 100vh;
    font-size: $mon17;
    display: flex;
    flex-direction: column;
    height: -moz-available;          /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    height: fill-available;
    
    .pageWrapper{
        display: flex;
        flex-direction: column;


        .title{
            grid-area: title;
            color: $tundora;
            font-family: MonSemiBold;
            margin: 2rem auto 2rem auto;
            text-align: center;
        }

        .image{
            display: block;
            height: 14rem;
            margin: auto
        }

        .contentWrapper{

            display: block;
            margin: auto;
            height: 10rem;

            .content{
                grid-area: content;
                color: $doveGray;
                font-size: $mon13;
                text-align: left;
                word-wrap: break-word;
                margin: 1rem auto 0 auto;
                padding: 0 2rem;
            }
        }
    }

    .dots{
        grid-area: dots;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 4rem;
        margin: 2rem auto 0 auto;

        .dot{
            margin:auto;
            height: 0.5rem;
            width: 0.5rem;
            background: $doveGray;
            border-radius: 50%;
        }

        .active{
            margin:auto;
            height: 0.5rem;
            width: 0.5rem;
            background: $cabaret;
            border-radius: 50%;
        }
    }
    
    .content{
        span{
            color: black;
            font-family: MonSemiBold;
        }
    }

    .link{
        margin: 2rem auto;
    }



}